<template>
    <div class="page">
        <md-card>
            <md-card-header>
                <h1>{{page.name | titlecase}}</h1>
            </md-card-header>
            <md-card-header v-if="page.type=='Link'">Jumping... {{page.content}}</md-card-header>
            <md-card-content v-html="page.content" v-if="page.type=='Page'"></md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    props: ["slug", "id"],
    data() {
        return {
            page: {}
        };
    },
    watch: {
        id() {
            this.loadPage();
        }
    },
    methods: {
        async loadPage() {
            if (this.id) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `pages/${this.id}`
                });
                this.page = result;
            } else if (this.slug) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `pages/get`,
                    params: {
                        slug: this.slug
                    }
                });
                this.page = result;
            }

            if (this.page.type == "Link") {
                location.replace(this.page.content);
            }
        }
    },

    created() {
        console.info("created");
        this.loadPage();
    }
};
</script>
<style>
.page {
    max-width: 1000px;
    margin: auto;
}
</style>


